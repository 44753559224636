import React, { useEffect, useRef, useState } from 'react';
import { GalaAttendeeController } from '../../controllers/GalaAttendeeController';
import { GalaController } from '../../controllers/GalaController';
import { IContactSchema, IGalaTicketSchema, IGalaAttendeeSchema } from '../../model/Gala/IGala';
import './Redeem.scss';

interface RedeemProps {
    galaAttendeeId: string;
}

const Redeem: React.FC<RedeemProps> = ({
    galaAttendeeId,
}) => {
    const [validTicket, setValidTicket] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [galaTicket, setGalaTicket] = useState<IGalaTicketSchema>();
    const [galaAttendee, setGalaAttendee] = useState<IGalaAttendeeSchema>();

    const [success, setSuccess] = useState(false);

    // Contact
    const contactRef = useRef<HTMLDivElement>(null);
    const scrollToContact = () => {
        if (contactRef !== null) {
            contactRef.current?.scrollIntoView();
        }
    }
    const [contact, setContact] = useState<IContactSchema>({
        fName: '',
        lName: '',
        address: '',
        postalCode: '',
        city: '',
        province: '',
        country: '',
        email: '',
        phone: '',
        mealChoice: 'Unselected',
        dietRestriction: '',
        seatingNotes: '',
        notes: '',
    })
    const updateContact = (e: any, field: any) => {
        const val = e.target.value;
        setContact((prevState) => ({
            ...prevState,
            [field]: val,
        }))
    }

    // ----------------------------------------------------------------
    // Helper Functions
    // ----------------------------------------------------------------

    const validField = (key: keyof IContactSchema) => {
        if (key === 'dietRestriction' || key === 'seatingNotes' || key === 'notes') return true;
        const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const phoneFormat = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        const val = contact[key];
        if (val === undefined) return false;
        if (val.length === 0) return false;
        if (key === 'email' && !val.match(mailFormat)) return false;
        if (key === 'phone' && !val.match(phoneFormat)) return false;
        return true;
    }

    const validContactForm = () => {
        let valid = true;
        Object.keys(contact).forEach((tKey) => {
            const key = tKey as keyof IContactSchema;
            if (!validField(key)) valid = false; 
        });
        return valid;
    }
   
    // ----------------------------------------------------------------
    // Controller Access
    // ----------------------------------------------------------------
    const galaController = new GalaController();
    const galaAttendeeController = new GalaAttendeeController();

    const getGalaTicket = async (galaTicketId: string) => {
        try {
            const galaResponse = await galaController.getGala();
            const tGalaTickets = galaResponse.data.gala.galaTickets;
            const tTicket = tGalaTickets.find((gT) => (
                gT._id === galaTicketId
            ));

            if (tTicket === undefined) throw new Error('Cannot find associated ticket');
            setGalaTicket(tTicket);
            setValidTicket(true);
        } catch (err) {
            // err
        }
        setIsLoading(false);
    };

    const getGalaAttendee = async () => {
        setIsLoading(true);
        try {
            const galaAttendeeResponse = await galaAttendeeController.getGalaAttendee(
                galaAttendeeId,
            );
            const tGalaAttendee = galaAttendeeResponse.data.galaAttendee;
            setGalaAttendee(tGalaAttendee);
            await getGalaTicket(tGalaAttendee.galaTicketId);
        } catch (err) {
            setIsLoading(false);
        }
    }
    
    const [RALoading, setRALoading] = useState(false);
    const registerAttendee = async () => {
        if (!validContactForm()) {
            scrollToContact();
            return;
        }
        if (RALoading) return;
        setRALoading(true);
        try {
            await galaAttendeeController.registerAttendee(
                galaAttendeeId, contact,
            );
            setSuccess(true);
        } catch (err) {
            //
        }
        setRALoading(false);
    }

    // ----------------------------------------------------------------------------
    // Use Effect Hooks
    // ----------------------------------------------------------------------------
    // On page load, init tickets and ticketListings
    useEffect(() => {
        getGalaAttendee();
    }, []);

    if (isLoading) return (
        <div className="messageContainer container">
            <h1>Loading ...</h1>
        </div>
    );
    if (!validTicket || galaTicket === undefined || galaAttendee === undefined) return(
        <div className="messageContainer container">
            <h1>Invalid ticket</h1>
        </div>
    );
    if (galaAttendee.registered) return (
        <div className="messageContainer container">
            <h1>Ticket is already registered.</h1>
        </div>
    );
    if (success) return (
        <div className="messageContainer container">
            <h1>Thank you {contact.fName} {contact.lName}!</h1>
            <p>We hope to see you soon!</p>
        </div>
    )

    return (
        <section className="section-redeem">
            <div className="contact-container container" ref={contactRef}>
                <div className="row">
                    <div className="col-12 headingContainer">
                        <div className="headingWrapper">
                            {/* <h2 className="heading">Redeem {galaTicket.name.en}</h2> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="contact row">
                            <div className="contactDesc col-md-12">
                                <h4>Enter contact information below:</h4>
                            </div>
                            <div className="row purchaser">
                                <div className="form-group col-md-4 col-sm-4">
                                    <label htmlFor={`fName_purchaser`}>Legal First Name</label>
                                    <input
                                        onChange={(e) => updateContact(e, 'fName')}
                                        type="text"
                                        className={`form-control ${validField('fName') ? '' : 'contactInvalid'}`}
                                        id={`fName_purchaser`}
                                        placeholder="First Name"
                                    />
                                </div>
                                <div className="form-group col-md-4 col-sm-4">
                                    <label htmlFor={`lName_purchaser`}>Legal Last Name</label>
                                    <input
                                        onChange={(e) => updateContact(e, 'lName')}
                                        type="text"
                                        className={`form-control ${validField('lName') ? '' : 'contactInvalid'}`}
                                        id={`lName_purchaser`}
                                        placeholder="Last Name"
                                    />
                                </div>
                                <div className="form-group col-md-4 col-sm-4">
                                    <label htmlFor={`pName_purchaser`}>Preferred Name</label>
                                    <input
                                        onChange={(e) => updateContact(e, 'pName')}
                                        type="text"
                                        className={`form-control`}
                                        id={`pName_purchaser`}
                                        placeholder="Preferred Name"
                                    />
                                </div>
                                <div className="form-group col-md-12 col-sm-12">
                                    <label htmlFor={`address_purchaser`}>Address</label>
                                    <input
                                        onChange={(e) => updateContact(e, 'address')}
                                        type="text"
                                        className={`form-control ${validField('address') ? '' : 'contactInvalid'}`}
                                        id={`address_purchaser`}
                                        placeholder="Email"
                                    />
                                </div>
                                <div className="form-group col-md-4 col-sm-4">
                                    <label htmlFor={`zip_purchaser`}>Postal Code</label>
                                    <input
                                        onChange={(e) => updateContact(e, 'postalCode')}
                                        type="text"
                                        className={`form-control ${validField('postalCode') ? '' : 'contactInvalid'}`}
                                        id={`zip_purchaser`}
                                        placeholder="Postal Code"
                                    />
                                </div>
                                <div className="form-group col-md-4 col-sm-4">
                                    <label htmlFor={`city_purchaser`}>City</label>
                                    <input
                                        onChange={(e) => updateContact(e, 'city')}
                                        type="text"
                                        className={`form-control ${validField('city') ? '' : 'contactInvalid'}`}
                                        id={`city_purchaser`}
                                        placeholder="City"
                                    />
                                </div>
                                <div className="form-group col-md-4 col-sm-4">
                                    <label htmlFor={`prov_purchaser`}>Province</label>
                                    <input
                                        onChange={(e) => updateContact(e, 'province')}
                                        type="text"
                                        className={`form-control ${validField('province') ? '' : 'contactInvalid'}`}
                                        id={`prov_purchaser`}
                                        placeholder="Province"
                                    />
                                </div>
                                <div className="form-group col-md-12 col-sm-12">
                                    <label htmlFor={`country_purchaser`}>Country</label>
                                    <input
                                        onChange={(e) => updateContact(e, 'country')}
                                        type="text"
                                        className={`form-control ${validField('country') ? '' : 'contactInvalid'}`}
                                        id={`country_purchaser`}
                                        placeholder="Country"
                                    />
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <label htmlFor={`email_purchaser`}>Email</label>
                                    <input
                                        onChange={(e) => updateContact(e, 'email')}
                                        type="email"
                                        className={`form-control ${validField('email') ? '' : 'contactInvalid'}`}
                                        id={`email_purchaser`}
                                        placeholder="Email"
                                    />
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <label htmlFor={`mobile_purchaser`}>Phone</label>
                                    <input
                                        onChange={(e) => updateContact(e, 'phone')}
                                        type="text"
                                        className={`form-control ${validField('phone') ? '' : 'contactInvalid'}`}
                                        id={`phone_purchaser`}
                                        placeholder="Phone"
                                    />
                                </div>
                                {/* <div className="form-group col-md-6 col-sm-6">
                                    <label htmlFor={`mealChoice_purchaser`}>Meal Choice</label>
                                    <select
                                        onChange={(e) => updateContact(e, 'mealChoice')}
                                        className={`form-control ${validField('mealChoice') ? '' : 'contactInvalid'}`}
                                        id={`mealChoice_purchaser`}
                                    >
                                        <option value="" selected disabled hidden>Choose one</option>
                                        <option value="Steak">Grilled 8oz AAA Alberta Striploin Steak</option>
                                        <option value="Fish">Herb Crusted Pacific Lingcod</option>
                                    </select>
                                </div> */}
                                <div className="form-group col-md-6 col-sm-6">
                                    <label htmlFor={`mobile_purchaser`}>Dietary Restrictions</label>
                                    <input
                                        onChange={(e) => updateContact(e, 'dietRestriction')}
                                        type="text"
                                        className={`form-control ${validField('dietRestriction') ? '' : 'contactInvalid'}`}
                                        id={`dietRestriction_purchaser`}
                                        placeholder="Dietary Restrictions"
                                    />
                                </div>
                                <div className="form-group col-md-12 col-sm-12">
                                    <label htmlFor={`seatingNotes_purchaser`}>Seating Preference</label>
                                    <textarea
                                        onChange={(e) => updateContact(e, 'seatingNotes')}
                                        className={`form-control ${validField('seatingNotes') ? '' : 'contactInvalid'}`}
                                        id={`seatingNotes_purchaser`}
                                    />
                                    <p className="seatingCaution">We will do our best to accomodate, but we cannot guarantee your desired seating arrangement.</p>
                                </div>
                                <div className="form-group col-md-12 col-sm-12">
                                    <label htmlFor={`notes_purchaser`}>Additional Notes</label>
                                    <textarea
                                        onChange={(e) => updateContact(e, 'notes')}
                                        className={`form-control ${validField('notes') ? '' : 'contactInvalid'}`}
                                        id={`notes_purchaser`}
                                    />
                                </div>

                                <div className="form-group col-md-12">
                                    <button onClick={registerAttendee}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Redeem;